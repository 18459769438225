let parties = [
  "Lab",
  "SLa",
  "LD",
  "Prb",
  "Con",
  "SCo",
  "Gre",
  "SGr",
  "SNP",
  "SSN",
  "UKI",
  "SU",
  "Pla",
  "SPl",
  "Ind",
  "SI",
  "BNP",
  "NV",
  "NLD",
  "Unk",
  "Ref",
];

function generateEmptyDaySummary(date) {
  return {
    count: 0,
    ...parties.reduce((o, key) => ({ ...o, [key]: 0 }), {}),
    date: date,
  };
}

export function getGraphingData(canvassData, roll, plotPeriod, endDate) {
  if (!plotPeriod) {
    plotPeriod = 30;
  }
  if (!endDate) {
    endDate = new Date().toISOString().substring(0, 10);
  }

  let daySummary = getDaySummary(canvassData);
  let data = getRollingData(daySummary, roll);

  let graphing = [];
  for (let day of Object.keys(data)) {
    graphing.push(getChesterGraphing(data[day], roll));
  }

  graphing = graphing.sort((a, b) => new Date(a.date) - new Date(b.date));
  let startDate = addDays(new Date(endDate), -plotPeriod);

  graphing = graphing.filter((day) => new Date(endDate) >= new Date(day.date) && new Date(day.date) > startDate);

  if (graphing.length === 0) {
    while (startDate < new Date(endDate)) {
      const dateYMD = startDate.toJSON().substring(0, 10);
      graphing.push({ date: dateYMD, count: 0 });
      startDate = addDays(startDate, 1);
    }
  } else if (graphing.length < plotPeriod) {
    let lastDate = new Date(graphing.at(-1).date);
    while (lastDate < new Date(endDate)) {
      lastDate = addDays(lastDate, 1);
      const dateYMD = lastDate.toJSON().substring(0, 10);
      graphing.push({ date: dateYMD, count: 0 });
    }
  }

  return graphing;
}

export function getGraphingData2(canvassData, roll, plotPeriod, endDate) {
  if (!plotPeriod) {
    plotPeriod = 30;
  }
  if (!endDate) {
    endDate = new Date().toISOString().substring(0, 10);
  }

  let daySummary = getDaySummary2(canvassData);
  let data = getRollingData(daySummary, roll);
  let graphing = [];
  for (let day of Object.keys(data)) {
    graphing.push(getChesterGraphing(data[day], roll));
  }

  graphing = graphing.sort((a, b) => new Date(a.date) - new Date(b.date));
  let startDate = addDays(new Date(endDate), -plotPeriod);
  graphing = graphing.filter((day) => new Date(endDate) >= new Date(day.date) && new Date(day.date) > startDate);

  if (graphing.length === 0) {
    while (startDate < new Date(endDate)) {
      const dateYMD = startDate.toJSON().substring(0, 10);
      graphing.push({ date: dateYMD });
      startDate = addDays(startDate, 1);
    }
  }

  return graphing;
}

export function getDaySummary(canvassData) {
  let daySummary = {};
  for (let item of canvassData) {
    let dateCanvassed = item.dateCanvassed;
    if (dateCanvassed.includes("T")) {
      dateCanvassed = dateCanvassed.split("T")[0];
    }
    if (!daySummary[dateCanvassed]) {
      daySummary[dateCanvassed] = generateEmptyDaySummary(dateCanvassed);
    }
    daySummary[dateCanvassed].count++;

    if (parties.includes(item.response)) {
      daySummary[dateCanvassed][item.response]++;
    }
  }

  // infill missing dates within range
  let { minDate, maxDate } = findDateRange(daySummary);
  let currDate = minDate;
  while (currDate <= maxDate) {
    const currDateYMD = currDate.toJSON().substring(0, 10);
    if (!daySummary[currDateYMD]) {
      daySummary[currDateYMD] = generateEmptyDaySummary(currDateYMD);
    }
    currDate = addDays(currDate, 1);
  }

  return daySummary;
}

export function getDaySummary2(canvassData) {
  let daySummary = {};
  for (let item of canvassData) {
    let dateCanvassed = item.dateCanvassed;
    if (dateCanvassed.includes("T")) {
      dateCanvassed = dateCanvassed.split("T")[0];
    }
    if (!daySummary[dateCanvassed]) {
      daySummary[dateCanvassed] = generateEmptyDaySummary(dateCanvassed);
    }
    if (item.response !== "Unk") {
      daySummary[dateCanvassed].count += item.responseCount;
    }

    if (parties.includes(item.response) && item.response !== "Unk") {
      daySummary[dateCanvassed][item.response] += item.responseCount;
    }
  }

  // infill missing dates within range
  let { minDate, maxDate } = findDateRange(daySummary);

  let currDate = minDate;
  while (currDate <= maxDate) {
    const currDateYMD = currDate.toJSON().substring(0, 10);
    if (!daySummary[currDateYMD]) {
      daySummary[currDateYMD] = generateEmptyDaySummary(currDateYMD);
    }
    currDate = addDays(currDate, 1);
  }

  return daySummary;
}

export function findDateRange(daySummary) {
  let minDate = new Date("2099-12-31");
  let maxDate = new Date("1900-01-01");
  for (const day of Object.keys(daySummary)) {
    const date = new Date(day);
    if (date < minDate) {
      minDate = date;
    }
    if (date > maxDate) {
      maxDate = date;
    }
  }
  return { minDate, maxDate };
}

export function getRollingData(daySummary, roll) {
  // Need to add extra days at end for rolling average
  let { minDate, maxDate } = findDateRange(daySummary);

  for (let i = 1; i < roll; i++) {
    let date = addDays(maxDate, i).toJSON().substring(0, 10);
    daySummary[date] = generateEmptyDaySummary(date);
  }

  let rollData = {};
  for (let day of Object.values(daySummary)) {
    let rolledData = generateEmptyDaySummary(day.date);
    for (let rollDay = 0; rollDay < roll; rollDay++) {
      let rollDayString = addDays(new Date(day.date), -rollDay).toJSON().substring(0, 10);
      if (daySummary[rollDayString]) {
        rolledData.count += daySummary[rollDayString].count;
        for (let party of parties) {
          rolledData[party] += daySummary[rollDayString][party];
        }
      }
      rollData[day.date] = rolledData;
    }
  }
  return rollData;
}

// Adds in missing parties into a canvass summary
function cleanupCanvassSummary(summary) {
  let out = { ...summary };
  for (let party of parties) {
    if (!out[party]) out[party] = 0;
  }
  return out;
}

export function getChesterGraphing(daySummary, roll = 1) {
  daySummary = cleanupCanvassSummary(daySummary);

  let { date, LD, Prb, Lab, SLa, Con, SCo, SNP, SSN, Pla, SPl, UKI, SU, Gre, SGr, Ind, SI, BNP, NV, NLD, Ref } =
    daySummary;
  let count = daySummary.count ?? daySummary.total;
  let denominator = Lab + SLa + Con + SCo + SNP + SSN + Pla + SPl + UKI + SU + Gre + SGr + Ind + SI + BNP;

  if (denominator === 0) denominator = 1;

  let cLD, cLab, cCon, cSNP, cPla, cUKI, cGre, cInd, cBNP;
  cLD = LD + Prb / 2;
  cLab = (Lab + SLa) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cCon = (Con + SCo) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cSNP = (SNP + SSN) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cPla = (Pla + SPl) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cUKI = (UKI + SU) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cGre = (Gre + SGr) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cInd = (Ind + SI) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  cBNP = BNP * (1 + (Prb / 2 + NLD + Ref) / denominator);

  const graphing = {
    cLD: Math.round((cLD / count) * 100),
    cLab: Math.round((cLab / count) * 100),
    cCon: Math.round((cCon / count) * 100),
    cSNP: Math.round((cSNP / count) * 100),
    cPla: Math.round((cPla / count) * 100),
    cUKI: Math.round((cUKI / count) * 100),
    cGre: Math.round((cGre / count) * 100),
    cInd: Math.round((cInd / count) * 100),
    cBNP: Math.round((cBNP / count) * 100),
    cNV: Math.round((NV / count) * 100),
    count: count / roll,
    date: date,
  };

  return graphing;
}

export function addDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
