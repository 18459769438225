export function canAccess(user, permissionRequired) {
  if (!user.functions) return false;
  for (let f of user.functions) {
    if (f.functionName === permissionRequired) {
      return true;
    }
  }
  return false;
}

export function hasConstituencies(user) {
  if (!user.isValid) {
    return false;
  }
  for (let areaType of Object.keys(user.allowedConstituencies)) {
    if (user.allowedConstituencies[areaType].length > 0) {
      return true;
    }
  }
  return false;
}
