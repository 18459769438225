import React from "react";
import { BarChart, Bar, Cell, LabelList, ReferenceLine, XAxis, YAxis } from "recharts";

export default function PollingDayCAGroupGraph({ children }) {
  const colourMapping = {
    Lib: "#FAA61A",
    Lab: "#E60B0B",
    Con: "#2C0BE6",
    Gre: "#38CB07",
    Nat: "#742881",
  };

  return (
    <div>
      <BarChart width={250} height={100} data={children}>
        <ReferenceLine y={0} />
        <XAxis dataKey="name" axisLine={false} tick={false} height={15} />
        <YAxis axisLine={false} tick={false} width={10} domain={[-0.15, 0.15]} />
        <Bar dataKey="value" maxBarSize={50} isAnimationActive={false}>
          <LabelList dataKey="value" formatter={(value) => parseInt(value * 100)} position={"bottom"} />
          <LabelList dataKey="name" position={"bottom"} offset={20} />
          {children.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colourMapping[entry.name]} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
}
