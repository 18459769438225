import React, { useContext, useEffect, useState } from "react";
import { NavLink, Route, Routes, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Header, Menu, MenuItem } from "semantic-ui-react";
import { canAccess } from "../logic/userPermissionsHandler";
import { UserContext } from "./UserContext";

import CommitteeUsersList from "./CommitteeUsersList";
import CommitteeSearchMenu from "./CommitteeSearchMenu";

export default function CommitteeData() {
  const { committeeID } = useParams();
  const user = useContext(UserContext);
  return (
    <div>
      <Helmet>
        <title>Committee Data - {committeeID}</title>
      </Helmet>
      <Header as="h2">Committee data - {committeeID}</Header>
      <Menu secondary size="small" borderless pointing>
        {canAccess(user, "basicCanvassing") ? (
          <MenuItem as={NavLink} to="./committeeUsers" name="committee-users">
            Committee Users
          </MenuItem>
        ) : (
          <></>
        )}
        <MenuItem>
          <CommitteeSearchMenu />
        </MenuItem>
      </Menu>
      <Routes>
        <Route path="/" element={<div>Hello - you're at {committeeID}</div>} />
        <Route path="/committeeUsers" element={<CommitteeUsersList />} />
        <Route
          path="*"
          element={
            <div>
              <Header as="h1">404</Header>You are nowhere
            </div>
          }
        />
      </Routes>
    </div>
  );
}
