import React, { useState, useEffect, useContext } from "react";

import "fomantic-ui-css/semantic.css";
import { Container, Header, ModalHeader, ModalDescription, ModalContent, Modal } from "semantic-ui-react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

import { UserContext } from "./components/UserContext";
import "./App.css";
import SeatData from "./components/SeatData";
import MetaData from "./components/MetaData";
import FrontPage from "./components/FrontPage";
import MyCampaignChart from "./components/MyCampaignChart";
import NavBar from "./components/NavBar";
import PDHACheckup from "./components/PDHACheckup";
import RegisterStatusScreen from "./components/RegisterStatusScreen";
import TopCanvassersTable from "./components/TopCanvassersTable";
import ConstituencySelectionScreen from "./components/ConstituencySelectionScreen";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TopFormsTable from "./components/TopFormsTable";
import FormStatsScreen from "./components/FormStatsScreen";
import MinivanSyncsTable from "./components/MinivanSyncsTable";
import FormsProcessingScreen from "./components/FormsProcessingScreen";
import CommitteeList from "./components/CommitteeList";
import CommitteeData from "./components/CommitteeData";

import { userData } from "./logic/apiHandler";
import UserData from "./components/UserData";
import GESummaryTable from "./components/GESummaryTable";
import NationalData from "./components/NationalData";
import ConstituencyActivityTable from "./components/ConstituencyActivityTable";

function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export default function Site() {
  let [sessCookie, setSessCookie] = useState("");
  let [user, setUser] = useState({ isValid: false, isLoaded: false });
  useEffect(() => {
    setSessCookie(getCookie("DASHSESSIONID"));
  }, [document.cookie]);

  const helmetContext = {};

  useEffect(() => {
    (async () => {
      let userDetails = await userData.whoami();
      let roles = await userData.roles();
      let functions = await userData.functions();
      let allowedConstituencies = await userData.allowedConstituencies();

      let newUser = {
        email: userDetails.email,
        isValid: roles.length > 0,
        roles: roles,
        functions: functions,
        isLoaded: true,
        allowedConstituencies: allowedConstituencies,
      };
      console.log(newUser);
      setUser(newUser);
    })();
  }, [sessCookie]);

  return (
    <div className="app">
      <HelmetProvider context={helmetContext}>
        <Helmet defaultTitle="Dashboard" titleTemplate="Dash.-%s" />
        <UserContext.Provider value={user}>
          <Router>
            <NavBar />
            {sessCookie !== "" ? (
              <></>
            ) : (
              <Modal open={true}>
                <ModalHeader>Not logged in</ModalHeader>
                <ModalContent image>
                  <ModalDescription>
                    <a href="/api/glogin">Log in here</a>
                  </ModalDescription>
                </ModalContent>
              </Modal>
            )}

            <Container className="fluid" style={{ marginTop: "3em" }}>
              <Routes>
                <Route path="/" exact element={<FrontPage />} />
                <Route
                  path="/seatData"
                  element={
                    <Container fluid>
                      <Header as="h2">Seat data</Header>
                      <ConstituencySelectionScreen />
                    </Container>
                  }
                />
                <Route path="/metaData" element={<MetaData />} />
                <Route path="/seatData/:areaID/*" element={<SeatData />} />
                <Route path="/top-canvassers" element={<TopCanvassersTable />} />
                <Route path="/register-status-table" element={<RegisterStatusScreen />} />
                <Route path="/pdha-checkup" exact element={<PDHACheckup />} />
                <Route path="/my-campaign-stats" exact element={<MyCampaignChart />} />
                <Route path="/users" element={<UserData />} />
                <Route path="/nationalData" element={<NationalData />} />
                <Route path="/nationalData/geSummary" element={<GESummaryTable areaName="National" />} />
                <Route path="/nationalData/constituencyActivity" element={<ConstituencyActivityTable />} />
                <Route path="/top-forms-table" element={<TopFormsTable />} />
                <Route path="/forms/:formID" element={<FormStatsScreen />} />
                <Route path="/forms-processing" element={<FormsProcessingScreen />} />
                <Route path="/minivan-syncs" element={<MinivanSyncsTable />} />
                <Route path="/committees" exact element={<CommitteeList />} />
                <Route path="/committees/:committeeID/*" element={<CommitteeData />} />

                <Route
                  path="*"
                  element={
                    <div>
                      <h1>404</h1>This page does not exist..
                    </div>
                  }
                />
              </Routes>
            </Container>
          </Router>
        </UserContext.Provider>
      </HelmetProvider>
    </div>
  );
}
