import React from "react";
import { LineChart, BarChart, Bar, CartesianGrid, Cell, Line, XAxis, YAxis } from "recharts";

export function GraphRenderer({ children, chartType }) {
  const colourMap = {
    aS: "#2C0BE6",
    cS: "#E60B0B",
    vShut: "#FAA61A",
    vNotShut: "#000000",
    p: "#FAA61A",
  };

  function renderLines() {
    for (let dataPoint of children) {
      return Object.keys(dataPoint)
        .filter((key) => key !== "hour")
        .map((fieldName) => {
          return (
            <Line
              yAxisId="data"
              type="monotone"
              unit="%"
              dataKey={fieldName}
              key={fieldName}
              dot={false}
              stroke={colourMap[fieldName]}
              fill={colourMap[fieldName]}
              isAnimationActive={false}
            />
          );
        });
    }
  }

  return (
    <div>
      {chartType === "barChart" ? (
        <BarChart width={250} height={100} data={children}>
          <XAxis dataKey="hour" tick={true} interval={3} height={20} fontSize="smaller" />
          <YAxis
            yAxisId="data"
            domain={[-0.1, 0.1]}
            allowDataOverflow={true}
            tickFormatter={(value) => `${100 * value}%`}
            tickCount={3}
            fontSize="smaller"
            width={35}
          />
          <Bar yAxisId="data" dataKey="p" isAnimationActive={false} barSize={5}>
            {children.map((entry, index) => {
              const colour = entry.p >= 0 ? "#FAA61A" : "#000000";
              return <Cell key={`cell-${index}`} stroke={colour} fill={colour} />;
            })}
          </Bar>
        </BarChart>
      ) : (
        <LineChart width={250} height={100} data={children}>
          <CartesianGrid strokeDasharray="3 7" />
          <XAxis dataKey="hour" tick={true} interval={3} height={20} fontSize="smaller" />
          <YAxis
            yAxisId="data"
            domain={[0, 0.5]}
            allowDataOverflow={true}
            tickFormatter={(value) => `${100 * value}%`}
            tickCount={2}
            fontSize="smaller"
            width={35}
          />
          {renderLines()}
        </LineChart>
      )}
    </div>
  );
}
