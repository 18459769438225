import React from "react";
import { Dimmer, Header, Loader } from "semantic-ui-react";

export default function CustomDimmer({ dataLoaded, hasError, error }) {
  function getErrorMessage(error) {
    let code = error?.httpErrorCode.toString();
    if (code === "403") {
      return "Invalid user authorisation";
    } else if (code === "404") {
      return "Resource not found";
    } else if (code.startsWith("4")) {
      return "Client error - please check details and try again";
    } else if (code.startsWith("5")) {
      return `Server error - please wait a few minutes before trying again`;
    } else {
      return error.errorMessage;
    }
  }

  return (
    <div className="loader-container">
      <Dimmer active={!dataLoaded || hasError} inverted>
        <Loader active={!dataLoaded} content="Loading" style={{ color: "#FAA61A" }} />
          {hasError ? (
            <div>
              <Header as="h3">{error?.httpErrorCode}</Header>
              {getErrorMessage(error)}
            </div>
          ) : null}
      </Dimmer>
    </div>
  );
}
