export function getMyCampaignGraphingData(myCampaignStatsData) {
  if (typeof myCampaignStatsData === "string") {
    // getError(myCampaignStatsData)
  }

  let graphing = [];
  for (let stats of myCampaignStatsData) {
    let stat = {
      date: stats.date.substring(0, 10),
      totalRecordCount: stats.totalRecordCount,
      unnamed: parseFloat(stats.unnamed / stats.totalRecordCount).toFixed(2) * 100,
      nonGeocoded: parseFloat(stats.nonGeocoded / stats.totalRecordCount).toFixed(2) * 100,
      addressedNonGeocoded: parseFloat(stats.addressedNonGeocoded / stats.totalRecordCount).toFixed(2) * 100,
      linked: parseFloat(stats.linked / stats.totalRecordCount).toFixed(2) * 100,
      localised: parseFloat(stats.localised / stats.totalRecordCount).toFixed(2) * 100,
      linkedAndLocalised: parseFloat(stats.linkedAndLocalised / stats.totalRecordCount).toFixed(2) * 100,
      hasContact: parseFloat(stats.hasContact / stats.totalRecordCount).toFixed(2) * 100,
      hasEmail: parseFloat(stats.hasEmail / stats.totalRecordCount).toFixed(2) * 100,
      hasPhone: parseFloat(stats.hasPhone / stats.totalRecordCount).toFixed(2) * 100,
      postcodeOnly: parseFloat(stats.postcodeOnly / stats.totalRecordCount).toFixed(2) * 100,
      hasDistricts: parseFloat(stats.hasDistricts / stats.totalRecordCount).toFixed(2) * 100,
    };
    graphing.push(stat);
  }
  return generateMissingDays(graphing);
}

function generateMissingDays(data) {
  let { minDate, maxDate } = findDateRange(data);
  const dates = Object.values(data).map((day) => day.date);

  for (let date = minDate; date < maxDate; date.setDate(date.getDate() + 1)) {
    let isoDate = date.toISOString().split("T")[0];
    if (!dates.includes(isoDate)) {
      data.push({ date: isoDate });
    }
  }
  return data.sort((a, b) => new Date(a.date) - new Date(b.date));
}

function findDateRange(data) {
  let minDate = new Date("2099-12-31");
  let maxDate = new Date("1900-01-01");
  for (const day of Object.values(data)) {
    const date = new Date(day.date);
    if (date < minDate) {
      minDate = date;
    }
    if (date > maxDate) {
      maxDate = date;
    }
  }
  return { minDate, maxDate };
}

export function getQuarterDates(data) {
  let { minDate, maxDate } = findDateRange(data);
  let quarterDates = [];
  let startDate;
  let minDateMonth = minDate.getMonth() + 1;

  let startYear;
  if (minDateMonth > 10) {
    startDate += `${minDate.setFullYear(minDate.getFullYear() + 1).toString()}-01-01`;
    startYear = minDate.setFullYear(minDate.getFullYear() + 1).toString();
  } else {
    startDate = `${minDate.getFullYear().toString()}-`;
    startYear = minDate.getFullYear().toString();
    while (minDateMonth < 10) {
      if (minDateMonth % 3 === 0 || minDateMonth === 0) {
        startDate += `${minDateMonth + 1}-01`;
        break;
      } else {
        minDateMonth++;
      }
    }
  }

  let startDateString = new Date(startDate);
  while (startDateString < maxDate) {
    quarterDates.push(startDateString.toISOString().split("T")[0]);
    startDateString.setMonth(startDateString.getMonth() + 3);
  }

  return quarterDates;
}
