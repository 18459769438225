import React, { useState } from "react";
import { Form, FormButton, FormDropdown, FormInput, Grid, GridColumn } from "semantic-ui-react";

export default function LAForm({ addMarkers, setMarkers, addError, removeError, errorMessages }) {
  const [LA, setLA] = useState("");
  const [hasLAError, setHasLAError] = useState(false);
  const [PD, setPD] = useState("");
  const [hasPDError, setHasPDError] = useState(false);
  const [table, setTable] = useState("changes");

  function handleSubmit() {
    if (!hasLAError && !hasPDError) {
      addMarkers(LA, PD, table);
    }
  }

  function clearForm() {
    setLA("");
    setHasLAError(false);
    setPD("");
    setHasPDError(false);
    setTable("changes");
    setMarkers([]);
  }

  function validateLA(input) {
    input = input.toUpperCase();
    setLA(input);
    if (input === "" || /^[ENSW][0-9]{8}$/.test(input)) {
      setHasLAError(false);
      removeError("Invalid LA");
      return true;
    }

    addError("Invalid LA");
    return false;
  }

  function isValidPD(input) {
    if (input === "" || /^[A-Za-z0-9 .&/\\()_*\\-]{1,10}$/.test(input)) {
      setHasPDError(false);
      removeError(errorMessages.PD);
      return true;
    }

    addError(errorMessages.PD);
    return false;
  }

  // E05011396
  return (
    <Form>
      <Grid verticalAlign="bottom">
        <GridColumn width={3}>
          <FormInput
            label="LA"
            placeholder="LA"
            value={LA}
            onChange={(e) => validateLA(e.target.value)}
            error={hasLAError}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormInput
            label="PD"
            placeholder="PD"
            value={PD}
            onChange={(e) => (isValidPD(e.target.value) ? setPD(e.target.value) : setHasPDError(true))}
            error={hasPDError}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormDropdown
            label="Map Type"
            onChange={(e, d) => setTable(d.value)}
            defaultValue="changes"
            selection
            options={[
              { text: "Changes", value: "changes", key: "changes" },
              { text: "Live", value: "live", key: "live" },
            ]}
          />
        </GridColumn>
        <GridColumn width={2}>
          <FormButton
            onClick={handleSubmit}
            variant="primary"
            style={{ backgroundColor: "#FAA61A" }}
            content="Add Markers"
            disabled={!LA || !PD || !table}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormButton onClick={clearForm}>Clear</FormButton>
        </GridColumn>
      </Grid>
    </Form>
  );
}
