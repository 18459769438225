export function getShuttleworthGraphingData(data) {
  let cumulativeSize = 0;
  let cumulativeLDs = 0;

  let graphingData = [];
  for (let group of data.groups) {
    let groupData = { category: group.category };
    let barTotal = parseInt(group.D) + parseInt(group.P) + parseInt(group.others);
    if (barTotal === 0) {
      groupData.noData = 100;
    } else {
      groupData.dPercent = Math.round((parseInt(group.D) / barTotal) * 100);
      groupData.pPercent = Math.round((parseInt(group.P) / barTotal) * 100);
      groupData.othersPercent = 100 - groupData.dPercent - groupData.pPercent;
    }
    cumulativeSize += parseInt(group.size);
    cumulativeLDs += parseInt(group.extraLDs);
    groupData = { ...groupData, cumulativeSize, cumulativeLDs, target: data.target };
    graphingData.push(groupData);
  }

  return { graphingData, referenceDate: data.referenceDate };
}
