import React, { useEffect, useState } from "react";
import { metaData } from "../logic/apiHandler";

import { Helmet } from "react-helmet-async";
import RegisterStatusTable from "./RegisterStatusTable";
import { Loader, Dimmer, Container } from "semantic-ui-react";

export default function RegisterStatusScreen() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [registerStatusData, setRegisterStatusData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setRegisterStatusData(await metaData.registerStatus());
        setDataLoaded(true);
      } catch (e) {
        console.error(e);
        setDataLoaded(true);
        setHasError(true);
      }
    })();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Register status</title>
      </Helmet>
      <>
        <Dimmer active={!dataLoaded} inverted light="true">
          <Loader active={!dataLoaded} content="Loading" />
        </Dimmer>
        <Dimmer active={hasError} light="true">
          Error
        </Dimmer>
        {dataLoaded ? <RegisterStatusTable data={registerStatusData} /> : null}
      </>
    </div>
  );
}
