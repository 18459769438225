import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function RegisterStatusPDFDownloader(props) {
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addPage();

    //doc.addFont("Helvetica", "F1", "normal", "bold");
    doc.setFontSize(15);
    doc.text(
      "Date that the next register update is due from each Local Authority",
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: "center", maxWidth: doc.internal.pageSize.getWidth() - 30 }
    );

    doc.setFontSize(10);
    const date = new Date().toISOString().substring(0, 10);
    doc.text(
      `A date in the future indicates that they are up to date in Connect.
    A date in the past indicates that we are still awaiting the update published on that date.
    This list was last updated on ${date}`,
      doc.internal.pageSize.getWidth() / 2,
      17,
      { align: "center", maxWidth: doc.internal.pageSize.getWidth() - 30 }
    );

    const registerData = props.data.sort((a, b) => a.name.localeCompare(b.name));
    const tableColumn = [{ name: "Local Authority", nextUpdate: "Next Update" }];
    const tableRows = [];
    registerData.forEach((authority) => {
      const authorityData = [authority.name, authority.nextUpdate.split("T")[0].split("-").reverse().join("/")];
      tableRows.push(authorityData);
    });

    const margin = {
      left: 15,
      right: 15,
      top: 20,
      bottom: 20,
    };
    const spacing = 5;
    const sections = 2;
    const printWidth = doc.internal.pageSize.width - (margin.left + margin.right);
    const sectionWidth = (printWidth - (sections - 1) * spacing) / sections;

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      tableWidth: sectionWidth,
      rowPageBreak: "avoid",
      styles: { cellPadding: 1 },

      didDrawPage({ table, pageNumber }) {
        const docPage = doc.internal.getNumberOfPages();
        const nextShouldWrap = pageNumber % sections;

        if (nextShouldWrap) {
          doc.setPage(docPage - 1);
          table.settings.margin.left += sectionWidth + spacing;
        } else {
          table.settings.margin.left = margin.left;
        }
      },
      margin: { top: 30 },
      didParseCell({ cell }) {
        cell.height = 6.5;
      },
    });

    doc.deletePage(1);
    doc.save(`ConnectUpdatesStatus${date}.pdf`);
  };

  return (
    <div>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
}
