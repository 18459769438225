import React, { useState } from "react";
import { APIProvider, Map, useMap, MapControl, ControlPosition, AdvancedMarker } from "@vis.gl/react-google-maps";
import { Helmet } from "react-helmet-async";
import { Dropdown, Grid, GridColumn, Icon, List, ListIcon, ListItem, Message } from "semantic-ui-react";
import api from "../logic/apiHandler";
import BoundaryForm from "./MapBoundaryForm";
import LAForm from "./MapLAForm";
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY ?? "";

export default function PDHACheckup() {
  const map = useMap();
  const [markers, setMarkers] = useState([]);
  const [centre, setCentre] = useState({ lat: 53.9449775, lng: -2.520879 });
  const [zoomLevel, setZoomLevel] = useState(6);
  const [polygons, setPolygons] = useState([]);
  const [colours, setColours] = useState(["#0303FC", "#FAA61A", "#E60B0B", "#30f205"]);
  const [errors, setErrors] = useState([]);
  const mapConfigs = [
    {
      key: "roadmap",
      text: "Road Map",
      value: "Road Map",
      mapTypeId: "roadmap",
    },
    {
      key: "satellite",
      text: "Satellite",
      value: "Satellite",
      mapTypeId: "satellite",
    },
  ];
  const [mapConfig, setMapConfig] = useState(mapConfigs[0]);
  const errorMessages = {
    query: "Cannot get boundary coordinates. Please try again in a few minutes.",
    boundary: "Invalid boundary ONS",
    pollingDistrict: "Polling district not found",
    boundaryAdded: "Boundary already added to map",
    LA: "Invalid LA",
    PD: "Invalid PD",
  };

  async function drawPolygon(boundary, layer) {
    let coordinates;
    try {
      const res = await api.metaData.pdhaBoundary(layer, boundary);
      coordinates = getPolygonCoordinates(res);
      removeError(errorMessages.query);
    } catch (e) {
      addError(errorMessages.query);
    }

    if (!coordinates) {
      addError(errorMessages.boundary);
    } else {
      removeError(errorMessages.boundary);
      const colour = colours[0];
      colours.shift();
      setColours(colours);
      const polygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: colour,
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: colour,
        fillOpacity: 0.25,
      });
      setPolygons([...polygons, { ons: boundary, colour, polygonDetails: polygon }]);
      polygon.setMap(map);
    }
    setZoomLevel(8);
  }

  function getPolygonCoordinates(data) {
    let res = data.split("<coordinates>")[1].split("</coordinates>")[0];
    res = res.split(" ");

    return res.map((row) => {
      let coordinates = row.split(",");
      return { lat: parseFloat(coordinates[1]), lng: parseFloat(coordinates[0]) };
    });
  }

  function removePolygon(polygonToRemove) {
    setPolygons(polygons.filter((polygon) => polygon.ons !== polygonToRemove.ons));
    setColours([...colours, polygonToRemove.colour]);
    polygonToRemove.polygonDetails.setMap(null);
  }

  function getMapCentre(geocodes) {
    const lats = geocodes.map((codesArr) => codesArr.latitude);
    const lngs = geocodes.map((codesArr) => codesArr.longitude);
    const midLat = (Math.min(...lats) + Math.max(...lats)) / 2;
    const midLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;
    setCentre({ lat: midLat, lng: midLng });
    setZoomLevel(11);
  }

  async function addMarkers(LA, PD, table) {
    try {
      const markerGeocodes = await api.metaData.pdhaGeocodes(table, LA, PD);
      if (markerGeocodes.length) {
        removeError(errorMessages.pollingDistrict);
        setMarkers(markerGeocodes);
        getMapCentre(markerGeocodes);
      } else {
        addError(errorMessages.pollingDistrict);
      }
    } catch (e) {
      addError(errorMessages.pollingDistrict);
    }
  }

  function addError(error) {
    setErrors([...new Set([...errors, error])]);
  }

  function removeError(error) {
    setErrors(errors.filter((str) => str !== error));
  }

  function renderActiveBoundaries() {
    return polygons.map((polygon) => {
      return (
        <ListItem key={polygon.ons} style={{ color: polygon.colour, cursor: "pointer" }}>
          <ListIcon
            name="delete"
            style={{ color: polygon.colour, cursor: "pointer" }}
            onClick={() => removePolygon(polygon)}
          />
          {polygon.ons}
        </ListItem>
      );
    });
  }

  return (
    <div>
      <Helmet>PDHA Checkup</Helmet>
      <LAForm
        addMarkers={addMarkers}
        setMarkers={setMarkers}
        addError={addError}
        removeError={removeError}
        errorMessages={errorMessages}
      />
      <BoundaryForm
        drawPolygon={drawPolygon}
        polygons={polygons}
        addError={addError}
        removeError={removeError}
        errorMessages={errorMessages}
      />
      {errors.length ? <Message error header="Unable to complete your request" list={errors} /> : <></>}
      <Grid verticalAlign="middle">
        <GridColumn width={8}>
          <List horizontal>{renderActiveBoundaries()}</List>
        </GridColumn>
      </Grid>
      <APIProvider apiKey={GOOGLE_MAPS_API_KEY} onLoad={() => console.log("Maps API has loaded.")}>
        <Map
          mapId="need a mapID" // TODO need a mapID
          style={{ height: window.innerHeight * 0.7 }}
          defaultZoom={6}
          defaultCenter={centre}
          center={centre}
          zoom={zoomLevel}
          mapTypeId={mapConfig.mapTypeId}
          onZoomChanged={(e) => setZoomLevel(e.detail.zoom)}
        >
          <MapControl position={ControlPosition.TOP_LEFT}>
            <Dropdown
              placeholder="Road Map"
              options={mapConfigs}
              onChange={(e, d) => setMapConfig(mapConfigs.find((config) => config.value === d.value))}
            />
          </MapControl>
          {markers.map((marker) => {
            return (
              <AdvancedMarker position={{ lat: marker.latitude, lng: marker.longitude }}>
                <Icon name="circle" size="large" style={{ color: marker.occupation > 0 ? "#ff0000" : "#0000ff" }} />
              </AdvancedMarker>
            );
          })}
        </Map>
      </APIProvider>
    </div>
  );
}
