import React from "react";
import { BarChart, XAxis, YAxis, CartesianGrid, Bar, Cell, ResponsiveContainer } from "recharts";

export default function ConstituencyChesterPrediction(props) {
  const colourMapping = {
    LD: "#FAA61A",
    Lab: "#E60B0B",
    Con: "#2C0BE6",
    Gre: "#38CB07",
    Ref: "#18B9D1",
    SNP: "#888800",
    Pla: "#3D6B48",
    Ind: "#ccc",
    other: "grey",
  };

  return (
    <div>
      <h3 className="title">
        Constituency Chester Prediction
        <br />
        All GE canvassing
      </h3>
      <ResponsiveContainer aspect={1}>
        <BarChart data={props.data}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="total" stackId="a" maxBarSize={50}>
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colourMapping[entry.name]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
