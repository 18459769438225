import React, { useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { GraphRenderer } from "./PollingGraphRenderer";
import PollingDayCAGroupGraph from "./PollingDayCAGroupGraph";
import {
  Button,
  Checkbox,
  Grid,
  GridColumn,
  Icon,
  List,
  ListItem,
  ListList,
  Modal,
  ModalActions,
  ModalHeader,
  ModalContent,
  ModalDescription,
  Popup,
} from "semantic-ui-react";

export default function PollingDayTable({ data }) {
  const [showHourlyDiffs, setShowHourlyDiffs] = useState(false);
  const [hideInactiveDistricts, setHideInactiveDistricts] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const optionalColumnGroups = {
    oursHourly: {
      header: "Ours - Overall (hourly change)",
      accessorKey: "ours",
      sortingFn: "alphanumeric",
    },
    oursGraph: {
      header: "Ours - Overall",
      accessorKey: "premiums",
      cell: (props) => <GraphRenderer chartType="barChart">{props.getValue()}</GraphRenderer>,
    },
    tellingGraph: {
      header: "TellingByInShuttleworth",
      accessorKey: "tellingByInShuttleworth",
      cell: (props) => <GraphRenderer chartType="lineChart">{props.getValue()}</GraphRenderer>,
    },
    activityGraph: {
      header: "Activity",
      accessorKey: "activity",
      cell: (props) => <GraphRenderer chartType="lineChart">{props.getValue()}</GraphRenderer>,
    },
    pollingCAGraph: {
      header: "PollingDayCAGroupGraph",
      accessorKey: "pollingDayCAGroupGraph",
      cell: (props) => <PollingDayCAGroupGraph>{props.getValue()}</PollingDayCAGroupGraph>,
    },
    oursOverall: {
      header: "Ours - Overall (%)",
      columns: [
        {
          header: "07",
          accessorFn: (originalRow) => getPremium(originalRow, 7),
        },
        {
          header: "08",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 8),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "09",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 9),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "10",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 10),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "11",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 11),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "12",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 12),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "13",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 13),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "14",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 14),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "15",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 15),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "16",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 16),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "17",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 17),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "18",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 18),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "19",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 19),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "20",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 20),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "21",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 21),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
        {
          header: "22",
          accessorFn: (originalRow) => getValueWithIndicator(originalRow, 22),
          cell: ({ cell }) => getIcon(cell.getValue()),
        },
      ],
    },
  };

  function getValueWithIndicator(originalRow, hour) {
    const value = getPremium(originalRow, hour);
    if (value === "-") {
      return value;
    } else {
      const prev = getPremium(originalRow, hour - 1);
      if (prev - value > 0.1) {
        return `${value} down`;
      } else if (value - prev > 0.1) {
        return `${value} up`;
      } else {
        return value;
      }
    }
  }

  const getPremium = (row, hour) => {
    let d = row.premiums.find((data) => data.hour === hour).p;
    return d === null ? "-" : (100 * d).toFixed(1);
  };

  function getIcon(value) {
    if (value === "-") {
      return value;
    } else if (value.includes("up")) {
      return (
        <div>
          {value.split(/\s/)[0]}&nbsp;
          <Icon name="caret up" color="green" />
        </div>
      );
    } else if (value.includes("down")) {
      return (
        <div>
          {value.split(/\s/)[0]}&nbsp;
          <Icon name="caret down" color="red" />
        </div>
      );
    } else {
      return (
        <div>
          {value.split(/\s/)[0]}&nbsp;
          <Icon name="minus" color="grey" />
        </div>
      );
    }
  }

  const columns = [
    {
      header: "PD",
      accessorKey: "POLLING_DISTRICT",
    },
    {
      header: "Ward",
      accessorKey: "ward",
    },
    {
      header: "Electorate",
      accessorKey: "electorate",
    },
    {
      header: "PV",
      accessorKey: "postal",
    },
    {
      header: "Shuttleworth",
      accessorKey: "shuttleworthSize",
    },
    {
      header: "Canvassed",
      accessorKey: "contacted",
      sortingFn: "alphanumeric",
    },
    {
      header: "Attempted",
      accessorKey: "attempted",
      sortingFn: "alphanumeric",
    },
    {
      header: "Voted",
      accessorKey: "telling",
      sortingFn: "alphanumeric",
    },
    {
      header: "% Def",
      accessorKey: "def",
      sortingFn: "alphanumeric",
    },
    {
      header: "ToKnock",
      accessorKey: "toKnock",
    },
  ];

  const table = useReactTable({
    columns: showHourlyDiffs
      ? [...columns, optionalColumnGroups.oursOverall]
      : [
          ...columns,
          optionalColumnGroups.oursHourly,
          optionalColumnGroups.oursGraph,
          optionalColumnGroups.tellingGraph,
          optionalColumnGroups.activityGraph,
          optionalColumnGroups.pollingCAGraph,
        ],
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "POLLING_DISTRICT",
          desc: false,
        },
      ],
    },
  });

  const columnDescriptions = {
    shuttleworthSize: "Size of shuttleworth",
    contacted: "Canvassed today, (% of them in shuttleworth)",
    attempted: "Attempted today, (% of them in shuttleworth)",
    telling: "Voted via. telling data (% in shuttleworth)",
    def: "% of people in shuttleworth who are still Def. Lib Dem",
    toKnock: "number of people in shuttleworth not attempted or telled yet",
    ours: "Differential turnout (change in last hour)",
    tellingByInShuttleworth: "Telling over time (shuttleworth vs. non-shut)",
    activity: "Number of attempts and contacts as percentage of shuttleworth",
  };

  return (
    <div>
      <Grid columns={2}>
        <GridColumn>
          Show hourly differentials
          <Checkbox
            className="mini"
            toggle
            checked={showHourlyDiffs}
            onChange={() => setShowHourlyDiffs(!showHourlyDiffs)}
          />{" "}
          &nbsp;&nbsp; Hide inactive districts
          <Checkbox
            className="mini"
            toggle
            checked={hideInactiveDistricts}
            onChange={() => setHideInactiveDistricts(!hideInactiveDistricts)}
          />
        </GridColumn>
        <GridColumn floated="right" width={2}>
          <Modal
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
            trigger={
              <Button floated="right" style={{ backgroundColor: "#faa61a", marginBottom: "5px" }} basic icon>
                <Icon name="help" size="large" />
              </Button>
            }
          >
            <ModalHeader>Polling Day dashboards</ModalHeader>
            <ModalContent>
              <ModalDescription>
                <p>
                  <strong>Data updates every hour - ~8 minutes after the hour.</strong>
                </p>
                <p>
                  It's important to try and get any data entry done before the end of the hour, so that it becomes
                  available when the dashboards refresh.
                </p>

                <List bulleted>
                  <ListItem>
                    <strong>Shuttleworth</strong> - {columnDescriptions.shuttleworthSize}
                  </ListItem>
                  <ListItem>
                    <strong>Canvassed</strong> - {columnDescriptions.contacted}
                  </ListItem>
                  <ListItem>
                    <strong>Attempted</strong> - {columnDescriptions.attempted}
                  </ListItem>
                  <ListItem>
                    <strong>Voted</strong> - {columnDescriptions.telling}
                  </ListItem>
                  <ListItem>
                    <strong>% def</strong> -{columnDescriptions.def}
                  </ListItem>
                  <ListItem>
                    <strong>Differential turnout</strong>
                    <ListList>
                      {columnDescriptions.ours.split(". ").map((line) => {
                        return <ListItem key={line}>{line}</ListItem>;
                      })}
                    </ListList>
                  </ListItem>
                  <ListItem>
                    <strong>ToKnock</strong> - {columnDescriptions.toKnock}
                  </ListItem>
                  <ListItem>
                    <strong>Graphs:</strong>
                    <ListList>
                      <ListItem>
                        <strong>Telling entry / split by in shuttleworth</strong>
                        <ListList>
                          {columnDescriptions.tellingByInShuttleworth.split(". ").map((line) => {
                            return <ListItem>{line}</ListItem>;
                          })}
                        </ListList>
                      </ListItem>
                      <ListItem>
                        <strong>Activity</strong>
                        <ListList>
                          {columnDescriptions.activity.split(". ").map((line) => {
                            return <ListItem key={line}>{line}</ListItem>;
                          })}
                        </ListList>
                      </ListItem>
                      <ListItem>
                        <strong>Diff Turnout</strong>
                        <ListList>
                          <ListItem>show the %turnout amongst different canvass analysis groups</ListItem>
                          <ListItem>
                            canvass analysis groups are the last bit of someone's canvass analysis (e.g. Yellow Labour =
                            Labour)
                          </ListItem>
                          <ListItem>helps differentiate between different parties running knock-up operations</ListItem>
                          <ListItem>
                            minor parties can show large figures here - e.g. if your 1 known Reform party supporter
                            votes early, they'll show highly here
                          </ListItem>
                        </ListList>
                      </ListItem>
                    </ListList>
                  </ListItem>
                </List>
              </ModalDescription>
            </ModalContent>
            <ModalActions>
              <Button onClick={() => setOpenModal(false)} style={{ backgroundColor: "#faa61a" }}>
                Got it!
              </Button>
            </ModalActions>
          </Modal>
        </GridColumn>
      </Grid>

      <div className="sticky-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}>
                      {
                        <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {columnDescriptions[header.id] ? (
                            <Popup
                              content={columnDescriptions[header.id].split(". ").map((line) => {
                                return <ListItem key={line}>{line}</ListItem>;
                              })}
                              trigger={<div style={{ fontSize: "10px", color: "#faa61a" }}>?</div>}
                              position="top center"
                            />
                          ) : null}
                          {{
                            asc: <Icon name="caret up" color="yellow" />,
                            desc: <Icon name="caret down" color="yellow" />,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      }
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              if (hideInactiveDistricts) {
                if (row.original.attempted === "-" && row.original.telling === "-") {
                  return;
                }
              }
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.id === "POLLING_DISTRICT") {
                      return <th key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</th>;
                    } else if (
                      cell.column.id === "tellingByInShuttleworth" ||
                      cell.column.id === "activity" ||
                      cell.column.id === "pollingDayCAGroupGraph"
                    ) {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            padding: 0,
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
