import React, { useMemo, useState } from "react";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

export default function SwitchAnalysisTable({ data }) {
  let throttlePause;
  let currentCell;
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const columns = [
    {
      header: "Old",
      accessorKey: "Old",
    },
    {
      header: "LD",
      accessorKey: "LD",
    },
    {
      header: "Prb",
      accessorKey: "Prb",
    },
    {
      header: "Con",
      accessorKey: "Con",
    },
    {
      header: "SCo",
      accessorKey: "SCo",
    },
    {
      header: "Lab",
      accessorKey: "Lab",
    },
    {
      header: "SLa",
      accessorKey: "SLa",
    },
    {
      header: "SNP",
      accessorKey: "SNP",
    },
    {
      header: "SSN",
      accessorKey: "SSN",
    },
    {
      header: "Pla",
      accessorKey: "Pla",
    },
    {
      header: "SPl",
      accessorKey: "SPl",
    },
    {
      header: "Gre",
      accessorKey: "Gre",
    },
    {
      header: "SGr",
      accessorKey: "SGr",
    },
    {
      header: "UKI",
      accessorKey: "UKI",
    },
    {
      header: "SU",
      accessorKey: "SU",
    },
    {
      header: "Ind",
      accessorKey: "Ind",
    },
    {
      header: "SI",
      accessorKey: "SI",
    },
    {
      header: "BNP",
      accessorKey: "BNP",
    },
    {
      header: "NLD",
      accessorKey: "NLD",
    },
    {
      header: "NV",
      accessorKey: "NV",
    },
    {
      header: "Ref",
      accessorKey: "Ref",
    },
    {
      header: "total",
      accessorKey: "total",
    },
  ];

  const table = useReactTable({ columns, data, getCoreRowModel: getCoreRowModel() });

  const colourMap = {
    LD: "#FAA61A",
    Prb: "#FAA61A",
    Con: "#4784F3",
    SCo: "#4784F3",
    Lab: "#E60B0B",
    SLa: "#E60B0B",
    SNP: "#ffff00",
    SSN: "#ffff00",
    Pla: "#3d6b48",
    SPl: "#3d6b48",
    Gre: "#38cb05",
    SGr: "#38cb05",
    UKI: "#a000ff",
    SU: "#a000ff",
  };

  const rowMap = {
    0: ["LD", "Prb"],
    1: ["LD", "Prb"],
    2: ["Con", "SCo"],
    3: ["Con", "SCo"],
    4: ["Lab", "SLa"],
    5: ["Lab", "SLa"],
    6: ["SNP", "SSN"],
    7: ["SNP", "SSN"],
    8: ["Pla", "SPl"],
    9: ["Pla", "SPl"],
    10: ["Gre", "SGr"],
    11: ["Gre", "SGr"],
    12: ["UKI", "SU"],
    13: ["UKI", "SU"],
  };

  function calculatePercentage(cell, total) {
    if (cell.column.id === "total" || cell.column.id === "Old") {
      return cell.getValue();
    } else {
      cell = parseInt(cell.getValue());
      total = parseInt(total);
      if (!isNaN(cell) && cell !== 0) {
        return (
          <div>
            <div style={{ fontSize: 15 }}>{Math.round((parseInt(cell) / total) * 100)}%</div>
            <div style={{ fontSize: 12 }}>{cell}</div>
          </div>
        );
      } else if (!isNaN(cell)) {
        return (
          <div>
            <div style={{ fontSize: 15 }}>0%</div>
            <div style={{ fontSize: 12 }}>{cell}</div>
          </div>
        );
      }
    }
  }

  function setHighlight(cell) {
    currentCell = { row: cell.row.id, column: cell.column.id };
    if (throttlePause) return;
    throttlePause = true;
    setTimeout(() => {
      setHoveredColumn(currentCell.column);
      setHoveredRow(currentCell.row);
      throttlePause = false;
    }, 50);
  }

  function shouldHighlight(cell) {
    if (cell.column.id === hoveredColumn || cell.row.id === hoveredRow) {
      return true;
    }
    return false;
  }

  return (
    <div className="sticky-table-container">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ minWidth: "60px" }}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const total = row.getValue("total");
                  const highlight = shouldHighlight(cell);
                  if (rowMap[cell.row.id] && rowMap[cell.row.id].includes(cell.column.id)) {
                    return (
                      <td
                        onMouseEnter={() => setHighlight(cell)}
                        style={{ background: colourMap[cell.column.id], opacity: highlight ? 0.8 : null }}
                        key={cell.column.id}
                      >
                        {calculatePercentage(cell, total)}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        onMouseEnter={() => setHighlight(cell)}
                        style={{ background: highlight ? `rgba(0, 187, 255, 0.1)` : null }}
                        key={cell.column.id}
                      >
                        {calculatePercentage(cell, total)}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
