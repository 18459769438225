import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

export default function FormStatsBarChart({ data, type }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (type === "last2Months") {
      getMonthlyData(data);
    } else if (type === "last2Days") {
      getHourlyData(data);
    }
  }, []);

  function getMonthlyData() {
    let maxDate = new Date();
    let minDate = new Date(new Date().setMonth(new Date(maxDate).getMonth() - 2)); // surely this cannot be legal
    let currDate = minDate;

    let monthlyData = [];
    while (currDate <= maxDate) {
      const currDateYMD = currDate.toISOString().split("T")[0];
      monthlyData.push(data.find((row) => row.date === currDateYMD) ?? { date: currDateYMD, count: 0 });
      currDate.setDate(currDate.getDate() + 1);
    }
    setChartData(sortData(monthlyData));
  }

  function getHourlyData(data) {
    let now = new Date(new Date().setMinutes(0, 0, 0));
    let start = new Date(now - 48 * 60 * 60 * 1000);
    let curr = start;
    curr.setHours(curr.getHours() + 1);

    let hourlyData = [];
    while (curr <= now) {
      const currISOStr = curr.toISOString();
      hourlyData.push(data.find((row) => row.datetime === currISOStr) ?? { datetime: currISOStr, count: 0 });
      curr.setHours(curr.getHours() + 1);
    }
    setChartData(sortData(hourlyData));
  }

  function sortData(data) {
    return data.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  }

  function get6HourlyTicks(data) {
    let minDate = data.reduce((a, b) => (a.date < b.date ? a : b)).date;
    let maxDate = data.reduce((a, b) => (a.date > b.date ? a : b)).date;
    let mondayTicks = [];

    let startDate = new Date(minDate);
    startDate.setDate(startDate.getDate() + ((1 + 7 - startDate.getDay()) % 7 || 7));

    while (startDate <= new Date(maxDate)) {
      mondayTicks.push(startDate.toISOString().split("T")[0]);
      startDate.setDate(startDate.getDate() + 7);
    }
    return mondayTicks;
  }

  // 2024-06-26T14:00:00.000Z
  function formatHourlyTicks(value) {
    let hour = Number.parseInt(value.split("T")[1].split(":")[0]);
    return hour;
  }

  function formatDailyTicks(value) {
    value = value.split("-");
    return `${value[2]}/${value[1]}`;
  }

  return (
    <ResponsiveContainer aspect={2} minWidth={250}>
      <BarChart width={700} height={250} data={chartData}>
        {type === "last2Days" ? (
          <XAxis
            dataKey="datetime"
            interval={5}
            tickFormatter={formatHourlyTicks}
            // tick={<HourlyTick />}
          />
        ) : (
          <XAxis dataKey="date" tickFormatter={formatDailyTicks} interval={6} />
        )}
        <YAxis domain={[0, 50]} />
        <Bar dataKey="count" fill="#FAA61A" isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  );
}
