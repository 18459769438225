import React, { useEffect, useState } from "react";
import { List, ListItem } from "semantic-ui-react";
import SeatLevelLabel from "./SeatLevelLabel";
import api from "../logic/apiHandler";
import { useParams, Link } from "react-router-dom";

export default function ChildrenConstituencies() {
  const [constituencies, setConstituencies] = useState({});
  const { areaID } = useParams();

  useEffect(() => {
    async function getConstituencies() {
      setConstituencies(await api.seatData.childAreas(areaID));
    }

    getConstituencies();
  }, []);

  function renderConstituencies() {
    return constituencies.children ? (
      constituencies?.children.map((constituency) => {
        const tag = constituency.label ? <SeatLevelLabel label={constituency.label.toLowerCase()} /> : null;
        return (
          <ListItem key={constituency.code}>
            <Link to={`/seatData/${constituency.code}`}>{constituency.name}</Link> {tag}
          </ListItem>
        );
      })
    ) : (
      <></>
    );
  }

  return (
    <div>
      <List bulleted>{renderConstituencies()}</List>
    </div>
  );
}
