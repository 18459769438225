import api from "./apiHandler";

let parties = [
  "Lab",
  "SLa",
  "LD",
  "Prb",
  "Con",
  "SCo",
  "Gre",
  "SGr",
  "SNP",
  "SSN",
  "UKI",
  "SU",
  "Pla",
  "SPl",
  "Ind",
  "SI",
  "BNP",
  "NV",
  "NLD",
  "Unk",
  "Ref",
];

export function getScorePerformanceGraphing(data, roll, isComparison) {
  let parsedData = parseData(data, isComparison);
  let scoreSummary = getScoreSummary(parsedData);
  let rolledData = getRollingData(scoreSummary, roll, isComparison);

  let graphing = [];
  for (let scoreData of Object.values(rolledData)) {
    graphing.push(getChesterGraphing(scoreData, roll));
  }
  graphing = getXYLine(graphing);

  return graphing;
}

function getXYLine(graphing) {
  for (let point of graphing) {
    if (point) {
      point.XY = point.score;
    }
  }
  return graphing;
}

export function parseData(data, isComparison) {
  let parsedData = [];
  let score = isComparison ? -100 : 0;
  for (score; score < 100; score++) {
    let rowsWithScore = data.filter((row) => parseInt(row.score) === score);
    if (rowsWithScore.length > 1) {
      let groupedData = { score, count: 0 };
      for (let row of rowsWithScore) {
        groupedData.count += parseInt(row.count);
        for (let value of Object.values(row)) {
          if (parties.includes(value)) {
            groupedData[value] = parseInt(row.count);
          }
        }
      }
      parsedData.push(groupedData);
    } else if (rowsWithScore.length === 1) {
      parsedData.push(rowsWithScore[0]);
    }
  }

  return parsedData;
}

function generateEmptyScoreSummary(score) {
  return {
    count: 0,
    ...parties.reduce((o, key) => ({ ...o, [key]: 0 }), {}),
    score: parseInt(score),
  };
}

export function getScoreSummary(data) {
  let scoreSummary = {};
  for (let item of data) {
    let score = item.score;
    if (!scoreSummary[score]) {
      scoreSummary[score] = generateEmptyScoreSummary(score);
    }

    scoreSummary[score].count += parseInt(item.count);
    for (let key of Object.keys(item)) {
      if (parties.includes(key)) {
        scoreSummary[score][key] += item[key];
      }
    }
  }

  return scoreSummary;
}

export function getRollingData(data, roll, isComparison) {
  if (roll === 0) {
    return data;
  } else if (!roll) {
    roll = 3;
  }

  let rollData = {};
  let score = isComparison ? -100 : 0;
  for (score; score <= 100; score++) {
    let rolledData = generateEmptyScoreSummary(score);
    let startScore = score - roll;
    let endScore = score + roll;

    for (let s = startScore; s <= endScore; s++) {
      if (!data[s]) continue;

      rolledData.count += data[s].count;
      for (let party of parties) {
        rolledData[party] += data[s][party];
      }
    }
    rollData[score] = rolledData;
  }

  return rollData;
}

function cleanupScoreSummary(summary) {
  for (let party of parties) {
    if (!summary[party]) {
      summary[party] = 0;
    }
  }
  return summary;
}

export function getChesterGraphing(scoreSummary, roll) {
  scoreSummary = cleanupScoreSummary(scoreSummary);
  let { score, count, LD, Prb, Lab, SLa, Con, SCo, SNP, SSN, Pla, SPl, UKI, SU, Gre, SGr, Ind, SI, BNP, NLD, Ref, NV } =
    scoreSummary;

  let denominator = Lab + SLa + Con + SCo + SNP + SSN + Pla + SPl + UKI + SU + Gre + SGr + Ind + SI + BNP;

  let cLD = LD + Prb / 2;
  let cLab = (Lab + SLa) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cCon = (Con + SCo) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cSNP = (SNP + SSN) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cPla = (Pla + SPl) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cUKI = (UKI + SU) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cGre = (Gre + SGr) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cInd = (Ind + SI) * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cBNP = BNP * (1 + (Prb / 2 + NLD + Ref) / denominator);
  let cNV = NV;
  let xCount = count / (2 * roll + 1); // average the count across the rolling period

  if (xCount > 10) {
    return {
      score,
      cLD: Math.round((cLD / count) * 100),
      cLab: Math.round((cLab / count) * 100),
      cCon: Math.round((cCon / count) * 100),
      cSNP: Math.round((cSNP / count) * 100),
      cPla: Math.round((cPla / count) * 100),
      cUKI: Math.round((cUKI / count) * 100),
      cGre: Math.round((cGre / count) * 100),
      cInd: Math.round((cInd / count) * 100),
      cBNP: Math.round((cBNP / count) * 100),
      cNV: Math.round((cNV / count) * 100),
      count: Math.round(xCount),
    };
  }
}
