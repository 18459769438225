import React, { useEffect, useState, useContext } from "react";
import { Dropdown, DropdownHeader } from "semantic-ui-react";
import SeatLevelLabel from "./SeatLevelLabel";
import { Link, useParams, generatePath, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

export default function ConstituencySearchMenu() {
  const [constituencies, setConstituencies] = useState({});
  const [highlightedRow, setHighlightedRow] = useState(null);
  const user = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setConstituencies(user?.allowedConstituencies ?? []);
  }, [user]);

  function getMenuOptions() {
    let options = [];
    Object.keys(constituencies).map((areaType) => {
      let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
      areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");

      options.push({
        text: areaName,
        value: areaName,
        content: <DropdownHeader content={areaName} />,
        disabled: true,
      });

      const areaTypeAbbreviation = getAreaTypeAbbreviation(areaType);
      for (let constituency of constituencies[areaType]) {
        const tag = constituency.label ? <SeatLevelLabel label={constituency.label.toLowerCase()} floatright /> : null;
        options.push({
          as: Link,
          key: `${areaType}:${constituency.code}`,
          text: `${constituency.name} - ${areaTypeAbbreviation}`,
          value: constituency.code,
          to: generatePath("/seatData/:areaID/*", { ...params, areaID: constituency.code }),
          label: tag,
        });
      }
    });
    return options;
  }

  function getAreaTypeAbbreviation(areaType) {
    switch (areaType) {
      case "westminster":
        return "W";
      case "westminster2024":
        return "W24";
      case "localAuthority":
        return "LA";
      case "european":
        return "Eu";
      default:
        return;
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      navigate(generatePath("/seatData/:areaID/*", { ...params, areaID: highlightedRow }));
    }
  }

  return (
    <Dropdown
      placeholder="Switch to:"
      search
      selection
      options={getMenuOptions()}
      onChange={(e, d) => {
        setHighlightedRow(d.value);
      }}
      onKeyDown={(e) => handleKeyDown(e)}
      closeOnChange={true}
    />
  );
}
