import React, { useState } from "react";
import { ResponsiveContainer, CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

export default function ConstituencyChesterFormulaTracker({ data }) {
  const xAxisTicks = getMonthTicks(data);
  const colourMapping = {
    cLD: "#FAA61A",
    cLab: "#E60B0B",
    cCon: "#2C0BE6",
    cGre: "#38CB07",
    cSNP: "#888800",
    cPla: "#3D6B48",
    cUKI: "#18B9D1",
    other: "grey",
  };

  function renderPartyLines() {
    return Object.keys(colourMapping).map((party) => {
      return (
        <Line
          yAxisId="percent"
          dataKey={party}
          dot={<CustomizedDot />}
          stroke={colourMapping[party]}
          key={party}
          isAnimationActive={false}
        />
      );
    });
  }

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value, dataKey, index } = props;
    if (value > 5 && index % 30 === 0) {
      return (
        <text x={cx} y={cy} dy={-9} fill={stroke} fontSize={10} textAnchor="middle">
          {value}
        </text>
      );
    }
    return null;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    let tick = props.ticks.find((x) => x === payload.value);

    if (tick) {
      tick = new Date(payload.value).toLocaleString("en-GB", { month: "short" });
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {tick}
          </text>
        </g>
      );
    }
    return null;
  };

  function getMonthTicks(data) {
    let minDate = new Date(data.reduce((a, b) => (a.date < b.date ? a : b)).date);
    minDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    let maxDate = new Date(data.reduce((a, b) => (a.date > b.date ? a : b)).date);
    maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
    let monthTicks = [];

    let startDate = new Date(minDate);

    while (startDate <= new Date(maxDate)) {
      let curr = new Date(startDate);
      curr = curr
        .toLocaleString("en-GB")
        .split(", ")[0]
        .replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1");
      monthTicks.push(curr);
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return monthTicks;
  }

  return (
    <div>
      <h3>Rolling tracker</h3>
      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart data={data}>
          <XAxis dataKey="date" tick={<CustomizedAxisTick ticks={xAxisTicks} />} interval={0} tickLine={false} />
          <YAxis yAxisId="percent" domain={[0, 100]} tickCount={5} />
          {renderPartyLines()}

          <Line
            yAxisId="percent"
            type="monotone"
            stroke="black"
            strokeDasharray="1 1"
            dataKey="cNV"
            dot={false}
            isAnimationActive={false}
          />
          <CartesianGrid vertical={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
