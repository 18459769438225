export function parseData(pollData) {
  let data = [];
  for (let dataset of pollData) {
    data.push({
      POLLING_DISTRICT: dataset.POLLING_DISTRICT,
      ward: dataset.ward,
      electorate: dataset.electorate,
      postal: dataset.postal,
      shuttleworthSize: dataset.shuttleworthSize,
      vLD: dataset.hourly.vld,
      vT: dataset.hourly.vt,
      vShut: dataset.hourly.vshut,
      vNotShut: dataset.hourly.vnotshut,
      cS: dataset.hourly.cs,
      aS: dataset.hourly.as,
      contacted:
        dataset.contactedT > 0
          ? `${dataset.contactedT} (${Math.round((100 * dataset.contactedS) / dataset.contactedT)}%)`
          : "-",
      attempted:
        dataset.attemptedT > 0
          ? `${dataset.attemptedT} (${Math.round((100 * dataset.attemptedS) / dataset.attemptedT)}%)`
          : "-",
      telling:
        dataset.tellingT > 0
          ? `${dataset.tellingT} (${Math.round((100 * dataset.tellingS) / dataset.tellingT)}%)`
          : "-",
      def:
        dataset.shutResponses > 0
          ? `${Math.round((100 * dataset.stillLibDem) / dataset.shutResponses)}% (${dataset.shutResponses})`
          : "-",
      ours: `${Math.round(100 * (dataset.vNowLD - dataset.vNowT))}% (${Math.round(
        100 * (dataset.vNowLD - dataset.vNowT - (dataset.vThenLD - dataset.vThenT))
      )}%)`,
      toKnock: dataset.shuttleworthSize - dataset.removedS,
      pollingDayCAGroupGraph: [
        { name: "Lib", value: dataset.tLib ?? 0 },
        { name: "Lab", value: dataset.tLab ?? 0 },
        { name: "Con", value: dataset.tCon ?? 0 },
        { name: "Gre", value: dataset.tGre ?? 0 },
        { name: "Nat", value: dataset.tNat ?? 0 },
      ],
    });
  }
  return getGraphingData(data);
}

function getGraphingData(data) {
  for (let dataset of data) {
    dataset.tellingByInShuttleworth = [];
    dataset.activity = [];
    dataset.premiums = [];
    for (let hour = 7; hour <= 22; hour++) {
      dataset.tellingByInShuttleworth.push({
        hour,
        vShut: dataset.vShut[hour],
        vNotShut: dataset.vNotShut[hour],
      });
      dataset.activity.push({
        hour,
        cS: dataset.cS[hour],
        aS: dataset.aS[hour],
      });
      dataset.premiums.push({
        hour,
        p: dataset.vLD[hour] !== null ? dataset.vLD[hour] - dataset.vT[hour] : null,
      });
    }
  }
  return data;
}
