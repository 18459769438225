export function convertIDToHex(id) {
  if (typeof id === "string" && Number.isInteger(parseInt(id))) {
    if (id % 1 !== 0) {
      return "";
    }
    id = parseInt(id);
  }

  if (!Number.isInteger(id) || id < 0) {
    return "";
  }

  let hex = id.toString(16).toUpperCase();
  hex = hex.split("").reverse().join("");

  let idAlpha = (id % 17) + 65;
  idAlpha = String.fromCharCode(idAlpha);

  return `EID${hex}${idAlpha}`;
}
