import React, { useState } from "react";
import { Form, FormButton, FormDropdown, FormInput, Grid, GridColumn } from "semantic-ui-react";

export default function BoundaryForm({ drawPolygon, polygons, addError, removeError, errorMessages }) {
  const [boundary, setBoundary] = useState(null);
  const [hasBoundaryError, setHasBoundaryError] = useState(false);
  const [layer, setLayer] = useState("live");

  function handleSubmit() {
    if (polygons.includes(boundary)) {
      addError(errorMessages.boundaryAdded);
    } else {
      removeError(errorMessages.boundaryAdded);
    }
    if (!hasBoundaryError) {
      drawPolygon(boundary, layer);
    }
  }

  function isValidBoundary(input) {
    input = input.toUpperCase();
    if (input === "" || /^[ENSW][0-9]{8}$/.test(input)) {
      setHasBoundaryError(false);
      removeError(errorMessages.boundary);
      return true;
    }

    addError(errorMessages.boundary);
    return false;
  }

  return (
    <Form>
      <Grid verticalAlign="bottom">
        <GridColumn width={3}>
          <FormInput
            label="Boundary"
            placeholder="Boundary"
            onChange={(e) =>
              isValidBoundary(e.target.value) ? setBoundary(e.target.value) : setHasBoundaryError(true)
            }
            error={hasBoundaryError}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormDropdown
            label="Layer"
            defaultValue="live"
            onChange={(e, d) => setLayer(d.value)}
            selection
            options={[
              {
                text: "Live",
                value: "live",
                key: "live",
              },
              {
                text: "Previous",
                value: "previous",
                key: "previous",
              },
            ]}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormButton
            onClick={handleSubmit}
            variant="primary"
            style={{ backgroundColor: "#FAA61A" }}
            content="Add Boundary"
            disabled={!boundary || !layer || polygons.length === 4}
          />
        </GridColumn>
      </Grid>
    </Form>
  );
}
