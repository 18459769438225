import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Icon } from "semantic-ui-react";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";

export default function MinivanSyncsTable() {
  const [syncsData, setSyncsData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        setSyncsData(await api.metaData.minivanSyncs());
      } catch (e) {
        console.log("error", e);
      }
    }

    getData();
  }, []);

  const columns = [
    {
      header: "Committee ID",
      accessorKey: "committeeID",
    },
    {
      header: "Committee Name",
      accessorKey: "committeeName",
    },
    {
      header: "Batch Count",
      accessorKey: "batchCount",
    },
    {
      header: "Oldest Seen",
      accessorKey: "oldestSeen",
      cell: ({ row }) => row.original.oldestSeen.split("T")[0],
    },
    {
      header: "Users",
      accessorKey: "users",
      enableSorting: false,
    },
  ];

  const table = useReactTable({
    columns,
    data: syncsData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "POLLING_DISTRICT",
          desc: false,
        },
      ],
    },
  });

  return (
    <div>
      <Helmet>
        <title>MiniVAN Syncs</title>
      </Helmet>
      <h3>Uncommitted MiniVAN Syncs</h3>
      <div className="sticky-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
