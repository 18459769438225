import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Surface, Symbols } from "recharts";
import { getMyCampaignGraphingData, getQuarterDates } from "../logic/myCampaignDataProcessor";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";

export default function MyCampaignChart() {
  const [disabledLines, setDisabledLines] = useState([]);
  const [myCampaignData, setMyCampaignData] = useState(null);
  const [quarterDateTicks, setQuarterDateTicks] = useState(null);
  const [lineDetails] = useState([
    { dataKey: "totalRecordCount", colour: "#2C0BE6", axis: "left", enabled: true },
    { dataKey: "unnamed", colour: "#E60B0B", axis: "right", enabled: true },
    { dataKey: "nonGeocoded", colour: "#575757", axis: "right", enabled: true },
    { dataKey: "addressedNonGeocoded", colour: "#FDBB30", axis: "right", enabled: true },
    { dataKey: "linked", colour: "#742881", axis: "right", enabled: true },
    { dataKey: "localised", colour: "#f5f502", axis: "right", enabled: true },
    { dataKey: "linkedAndLocalised", colour: "#5ec3f2", axis: "right", enabled: true },
    { dataKey: "hasContact", colour: "#360370", axis: "right", enabled: true },
    { dataKey: "hasEmail", colour: "#2a730d", axis: "right", enabled: true },
    { dataKey: "hasPhone", colour: "#195c4b", axis: "right", enabled: true },
    { dataKey: "postcodeOnly", colour: "#6e2904", axis: "right", enabled: true },
    { dataKey: "hasDistricts", colour: "#bf2a52", axis: "right", enabled: true },
  ]);

  useEffect(() => {
    async function getMyCampaignData() {
      let data = await api.metaData.myCampaignStats();
      data = getMyCampaignGraphingData(data);
      setMyCampaignData(data);
      setQuarterDateTicks(getQuarterDates(data));
    }

    getMyCampaignData();
  }, []);

  function renderLegend({ payload }) {
    return (
      <div className="legend">
        {payload.map((entry) => {
          const { dataKey, colour } = entry;
          const active = disabledLines.includes(dataKey);
          const style = {
            colour: active ? "#AAA" : "#000",
          };

          return (
            <span className="legend-item" onClick={() => toggleLine(dataKey)} style={style} key={dataKey}>
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={colour} />
                {active && <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  }

  function toggleLine(dataKey) {
    let line = lineDetails.find((lineDetail) => lineDetail.dataKey === dataKey);
    line.enabled = !line.enabled;
    if (disabledLines.includes(dataKey)) {
      setDisabledLines(disabledLines.filter((obj) => obj !== dataKey));
    } else {
      setDisabledLines([...disabledLines, dataKey]);
    }
  }

  function renderLines() {
    return lineDetails.map((line) => {
      return (
        <Line
          yAxisId={line.axis}
          type="monotone"
          dataKey={line.dataKey}
          key={line.dataKey}
          dot={false}
          stroke={line.colour}
          isAnimationActive={false}
          style={{ opacity: line.enabled ? 1 : 0 }}
        />
      );
    });
  }

  function formatDateTick(value) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let date = new Date(value);
    return `${months[date.getMonth()]} ${String(date.getFullYear()).substring(2)}`;
  }

  return (
    <div>
      <Helmet>
        <title> My Campaign Chart</title>
      </Helmet>
      {myCampaignData ? (
        <LineChart
          width={1000}
          height={500}
          data={myCampaignData}
          margin={{
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 7" />
          <XAxis
            dataKey="date"
            ticks={quarterDateTicks}
            type="category"
            tickFormatter={(value) => formatDateTick(value)}
          />
          <YAxis
            yAxisId="left"
            tickCount={10}
            width={80}
            domain={[0, (dataMax) => Math.round((dataMax + 2000) / 2000) * 2000]}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />
          <Legend verticalAlign="top" payload={lineDetails} content={renderLegend} />
          {renderLines()}
        </LineChart>
      ) : (
        <></>
      )}
    </div>
  );
}
