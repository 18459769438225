import { getChesterGraphing } from "./sharedProcessor";

export function calculateMonthlyPartyData(data) {
  const parties = {
    Lab: 0,
    SLa: 0,
    LD: 0,
    Prb: 0,
    Con: 0,
    SCo: 0,
    Gre: 0,
    SGr: 0,
    SNP: 0,
    SSN: 0,
    UKI: 0,
    SU: 0,
    Pla: 0,
    SPl: 0,
    Ind: 0,
    SI: 0,
    BNP: 0,
    NV: 0,
    NLD: 0,
    Unk: 0,
    Ref: 0,
  };

  let graphData = [];

  for (let responseData of data) {
    let responseDate = new Date(responseData.dateCanvassed).toLocaleString("en-GB", {
      timeZone: "UTC",
      month: "short",
      year: "numeric",
    });
    let sortingDate = responseData.dateCanvassed;

    let resExists = graphData.find((data) => data.date === responseDate); // name
    let res;

    res = resExists ?? { date: responseDate, sortingDate, total: 0, ...parties };
    //res.total++;
    res.total += responseData.responseCount;

    if (res.hasOwnProperty(responseData.response)) {
      //res[responseData.response]++;
      res[responseData.response] += responseData.responseCount;
    } else {
      //res.other++;
      res.other += responseData.responseCount;
    }

    if (!resExists) {
      graphData.push(res);
    }
  }

  graphData.sort((a, b) => new Date(a.sortingDate) - new Date(b.sortingDate));
  return graphData;
}

export function calculateMonthlyChesterData(data) {
  const monthlyData = calculateMonthlyPartyData(data);
  const monthlyChesterData = [];
  for (let month of monthlyData) {
    let rawUnk = month.Unk;
    month.total -= month.Unk;
    month.Unk = 0;

    let chesterData = getChesterGraphing(month);
    monthlyChesterData.push({
      LD: chesterData.cLD,
      Con: chesterData.cCon,
      Lab: chesterData.cLab,
      Gre: chesterData.cGre,
      SNP: chesterData.cSNP,
      Pla: chesterData.cPla,
      UKI: chesterData.cUKI,
      other: chesterData.cInd + chesterData.cBNP,
      NV: chesterData.cNV,
      Unk: rawUnk,
      Ref: month.Ref,
      total: chesterData.count,
      date: month.date,
      sortingDate: month.sortingDate,
    });
  }
  return monthlyChesterData;
}

export function calculateBarChartData(data) {
  const parties = {
    Lab: 0,
    SLa: 0,
    LD: 0,
    Prb: 0,
    Con: 0,
    SCo: 0,
    Gre: 0,
    SGr: 0,
    SNP: 0,
    SSN: 0,
    UKI: 0,
    SU: 0,
    Pla: 0,
    SPl: 0,
    Ind: 0,
    SI: 0,
    BNP: 0,
    NV: 0,
    NLD: 0,
    Unk: 0,
    Ref: 0,
    other: 0,
    count: 0,
  };

  for (let responseData of data) {
    if (responseData.response === "Unk") {
      // ignore
    } else if (parties.hasOwnProperty(responseData.response)) {
      parties[responseData.response] += responseData.responseCount;
      parties.count += responseData.responseCount;
    } else {
      parties.other += responseData.responseCount;
      parties.count += responseData.responseCount;
    }
  }

  let cResult = getChesterGraphing(parties, 1);

  const barChartData = [
    { name: "LD", total: cResult.cLD },
    { name: "Lab", total: cResult.cLab },
    { name: "Con", total: cResult.cCon },
    { name: "SNP", total: cResult.cSNP },
    { name: "Pla", total: cResult.cPla },
    { name: "Ref", total: cResult.cUKI },
    { name: "Gre", total: cResult.cGre },
    { name: "Ind", total: cResult.cInd },
    { name: "NV", total: cResult.cNV },
  ];
  return barChartData;
}

export function getTableHeaders(data) {
  let headers = data.map((monthlyData) => {
    return {
      id: monthlyData.date,
      header: monthlyData.date,
      accessorKey: monthlyData.date,
    };
  });

  return [{ id: "Period Commencing", header: "Period Commencing", accessorKey: "Period Commencing" }, ...headers];
}

export function formatTableData(data) {
  const parties = ["LD", "Con", "Lab", "Gre", "SNP", "Pla", "UKI", "other", "NV", "Unk", "total"];
  let res = [];
  for (let party of parties) {
    let row = {
      "Period Commencing": party === "UKI" ? "Ref" : party,
    };
    data.forEach((monthData) => {
      let date = monthData.date;
      row[date] = monthData[party];
    });
    res.push(row);
  }

  return res;
}

export function getTableColumns(data) {
  const dateHeaders = data.map((monthData) => {
    return { header: monthData.date, accessorKey: monthData.date };
  });
  return [{ header: "Period Commencing", accessorKey: "Period Commencing" }, ...dateHeaders];
}
