import React, { useContext, useEffect, useState } from "react";
import { useParams, NavLink, Routes, Route } from "react-router-dom";
import { Menu, Header, Label } from "semantic-ui-react";
import api from "../logic/apiHandler";
import CanvassCharts from "./CanvassCharts";
import PollingDayScreen from "./PollingDayScreen";
import ScorePerformanceScreen from "./ScorePerformanceScreen";
import ScoreProfileScreen from "./ScoreProfileScreen";
import ChesterCharts from "./ChesterCharts";
import ShuttleworthResponseGraph from "./ShuttleworthResponseGraph";
import SwitchAnalysisScreen from "./SwitchAnalysisScreen";
import PollingDistrictCodesTable from "./PollingDistrictCodesTable";
import ConstituencySearchMenu from "./ConstituencySearchMenu";
import { Helmet } from "react-helmet-async";
import { canAccess } from "../logic/userPermissionsHandler";
import { UserContext } from "./UserContext";
import ChildrenConstituencies from "./ChildrenConstituencies";
import SeatLevelLabel from "./SeatLevelLabel";
import SeatTypeLabel from "./SeatTypeLabel";
import GESummaryTable from "./GESummaryTable";
import QuestionsListTable from "./QuestionsListTable";
import ActivistCodesScreen from "./ActivistCodesScreen";

export default function SeatData() {
  let { areaID } = useParams();
  const [areaName, setAreaName] = useState("");
  const [areaLabel, setAreaLabel] = useState("");
  const user = useContext(UserContext);
  const [areaType, setAreaType] = useState("");
  const [isParentField, setIsParentField] = useState(false);

  useEffect(() => {
    (async () => {
      setAreaName("");
      setAreaLabel("");
      let data = await api.seatData.info(areaID);
      const field = data.fieldcode;
      if (field === "CR" || field === "ER" || field === "DevR" || field === "DBU") {
        setIsParentField(true);
      } else {
        setIsParentField(false);
      }
      setAreaLabel(data.label ? data.label : "");
      setAreaName(data.name);
      setAreaType(field);
    })();
  }, [areaID]);

  return (
    <div>
      <Helmet>
        <title>Seat Data - {areaName}</title>
      </Helmet>
      <Header as="h2">
        Seat data - {areaName}
        {areaLabel !== "" ? <SeatLevelLabel label={areaLabel} /> : null}
        {areaType !== "" ? <SeatTypeLabel type={areaType} /> : null}
      </Header>
      {isParentField ? (
        <ChildrenConstituencies />
      ) : (
        <>
          <Menu secondary size="small" borderless pointing>
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./canvassing" name="canvassing">
                Canvassing
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./pollingDay" name="polling-day">
                Polling Day
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "scoreData") ? (
              <Menu.Item as={NavLink} to="./scoreProfile" name="score-profile-charts">
                Score profiles
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "scoreData") ? (
              <Menu.Item as={NavLink} to="./scorePerformance" name="score-performance-chester-charts">
                Score performance
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./shuttleworth" name="shuttleworth">
                Shuttleworth
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./switchAnalysis" name="switch-analysis">
                Switch analysis
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./geCanvass" name="ge-canvassing">
                GE canvassing
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./pdCodes" name="pd codes">
                PD codes
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "privateCharlie") ? (
              <Menu.Item as={NavLink} to="./surveyQuestions" name="survey-questions">
                Survey Questions
              </Menu.Item>
            ) : null}

            {canAccess(user, "privateCharlie") ? (
              <Menu.Item as={NavLink} to="./activistCodes" name="activist-codes">
                Activist Codes
              </Menu.Item>
            ) : null}

            <Menu.Item>
              <ConstituencySearchMenu />
            </Menu.Item>
          </Menu>
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  Hello - you're at {areaID} - {areaName}
                </div>
              }
            />
            <Route path="/canvassing" element={<CanvassCharts areaName={areaName} />} />
            <Route path="/pollingDay" element={<PollingDayScreen areaName={areaName} />} />
            <Route path="/scoreProfile" element={<ScoreProfileScreen areaName={areaName} />} />
            <Route path="/scorePerformance" element={<ScorePerformanceScreen areaName={areaName} />} />
            <Route path="/geCanvass" element={<ChesterCharts areaName={areaName} />} />
            <Route path="/shuttleworth" element={<ShuttleworthResponseGraph areaName={areaName} />} />
            <Route path="/switchAnalysis" element={<SwitchAnalysisScreen areaName={areaName} />} />
            <Route path="/pdCodes" element={<PollingDistrictCodesTable areaName={areaName} />} />
            <Route path="/geSummary" element={<GESummaryTable areaName={areaName} />} />
            <Route path="/surveyQuestions" element={<QuestionsListTable areaName={areaName} />} />
            <Route path="/activistCodes" element={<ActivistCodesScreen areaName={areaName} />} />
            <Route
              path="*"
              element={
                <div>
                  <h1>404</h1>You are nowhere
                </div>
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
}
