import React from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

export default function LoadingScreen() {
  return (
    <div className="loader-container">
      <ClimbingBoxLoader color="#FAA61A" size={30} />
    </div>
  );
}
