import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import SeatLevelLabel from "./SeatLevelLabel";
import { Button, ButtonGroup, Header, Icon } from "semantic-ui-react";
import { generatePath, Link, useNavigate } from "react-router-dom";

export default function ConstituencyActivityTable() {
  const [activityData, setActivityData] = useState([]);
  const [activityType, setActivityType] = useState("all");

  useEffect(() => {
    (async () => {
      try {
        let data = await api.nationalData.dailyContact();
        setActivityData(tidyData(data));
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, []);

  function tidyData(data) {
    let numKeys = ["telling", "attempts", "contacts", "total"];
    for (let row of data) {
      Object.keys(row).map((key) => {
        if (numKeys.includes(key)) {
          row[key] = parseInt(row[key]);
        }
      });
    }
    return data;
  }

  const columns = [
    /*{
      header: "Code",
      accessorKey: "code",
      enableSorting: false,
      cell: ({ row }) => (
        <Link to={generatePath(`/seatData/${row.original.code}/pollingDay`)}>{row.original.code}</Link>
      ),
    },*/
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => (
        <Link to={generatePath(`/seatData/${row.original.code}/pollingDay`)}>{row.original.name}</Link>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (row.original.status ? <SeatLevelLabel label={row.original.status} /> : null),
      enableSorting: false,
    },
    {
      header: "Telling",
      accessorKey: "telling",
    },
    {
      header: "Attempts",
      accessorKey: "attempts",
    },
    {
      header: "Contacts",
      accessorKey: "contacts",
    },
    {
      header: "Total",
      accessorKey: "total",
    },
  ];

  const table = useReactTable({
    columns,
    data: activityData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
    },
  });

  return (
    <div>
      <Helmet>
        <title>PD activity</title>
      </Helmet>
      <Header as="h2">Polling day constituency activity</Header>
      <br />
      Filter by &nbsp;&nbsp;
      <ButtonGroup>
        <Button onClick={() => setActivityType("all")} color={activityType === "all" ? "yellow" : null}>
          All
        </Button>
        <Button onClick={() => setActivityType("targ")} color={activityType === "targ" ? "yellow" : null}>
          Targets
        </Button>
        <Button onClick={() => setActivityType("nontarg")} color={activityType === "nontarg" ? "yellow" : null}>
          Non-targets
        </Button>
      </ButtonGroup>
      <div className="sticky-table-container">
        <table style={{ marginTop: "10px" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon name="caret up" color="yellow" />,
                          desc: <Icon name="caret down" color="yellow" />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              if (activityType === "targ") {
                if (!row.original.status) {
                  return;
                }
              } else if (activityType === "nontarg") {
                if (row.original.status) {
                  return;
                }
              }
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
