import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";
import Dimmer from "./Dimmer";
import api from "../logic/apiHandler";
import { Container } from "semantic-ui-react";

export default function ScoreProfileCharts({ scoreName, colour, title }) {
  const [scoreProfileData, setScoreProfileData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  let { areaID } = useParams();

  useEffect(() => {
    async function getScoreProfileData() {
      try {
        await getCumulativePeopleScores();
        setDataLoaded(true);
      } catch (e) {
        console.error(e);
        setError(e);
        setDataLoaded(true);
        setHasError(true);
      }
    }

    getScoreProfileData();
  }, [areaID, scoreName]);

  async function getCumulativePeopleScores() {
    let data = await api.seatData.scoreProfile(areaID, scoreName);
    let cumulativePeopleScore = 0;
    for (let score = 0; score < 100; score++) {
      let scorePeople = data.find((row) => row.score === score);
      if (!scorePeople) {
        scorePeople = { score, people: 0 };
        data.push(scorePeople);
      }
      cumulativePeopleScore += scorePeople.people;
      scorePeople.cumulativePeople = cumulativePeopleScore;
    }

    data = data.sort((a, b) => a.score - b.score);
    setScoreProfileData(data);
  }

  function renderCumulativeLine() {
    return (
      <Line
        yAxisId="cumulativePeople"
        type="monotone"
        dataKey="cumulativePeople"
        stroke="grey"
        dot={false}
        strokeDasharray="5 5"
        isAnimationActive={false}
      />
    );
  }

  return (
    <div>
      <h3 className="title">{title}</h3>
      <div>
        {!dataLoaded || hasError ? (
          <Dimmer dataLoaded={dataLoaded} hasError={hasError} error={error} />
        ) : (
          <LineChart width={500} height={300} data={scoreProfileData}>
            <CartesianGrid strokeDasharray="3 7" />
            <XAxis dataKey="score" tick={true} ticks={[0, 25, 50, 75]} type="number" />
            <YAxis
              yAxisId="numPeople"
              label={{ value: "numPeople", angle: -90, position: "insideLeft" }}
              domain={[0, 6000]}
              tickCount={4}
            />
            <YAxis
              yAxisId="cumulativePeople"
              orientation="right"
              label={{
                value: "cumulativePeople",
                angle: 90,
                position: "insideRight",
                padding: "15px",
              }}
              domain={[0, 80000]}
              tickCount={5}
            />
            {renderCumulativeLine()}
            <Line
              yAxisId="numPeople"
              type="monotone"
              dataKey="people"
              stroke={colour}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        )}
      </div>
    </div>
  );
}
