import React from "react";
import { Label } from "semantic-ui-react";

export default function SeatTypeLabel({ type, floatright }) {
  function getTitle(type) {
    switch (type) {
      case "W":
        return "old westminster";
      case "W2024":
        return "new westminster";
      case "ER":
        return "European region";
      case "DevC":
        return "Devolved constituency";
      case "DevR":
        return "Devolved region";
      case "DBUWR":
        return "Ward";
      case "DBU":
        return "Local authority";
      case "CR":
        return "County council";
      case "CEDR":
        return "County division";

      default:
        return "";
    }
  }

  return (
    <Label color="grey" horizontal size="mini" className={floatright ? "right floated" : ""}>
      {getTitle(type)}
    </Label>
  );
}
