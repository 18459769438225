import React, { useEffect, useMemo, useState } from "react";
import { flexRender, getCoreRowModel, useReactTable, createColumnHelper } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Link, generatePath } from "react-router-dom";

export default function TopFormsTable() {
  const [tableData, setTableData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const columnHelper = createColumnHelper();

  useEffect(() => {
    async function getTopFormsData() {
      try {
        setTableData(await api.metaData.topForms());
      } catch (e) {
        console.log("e", e);
      }
    }
    getTopFormsData();
  }, []);

  const columns = [
    {
      header: "Organisation Name",
      accessorKey: "organisationName",
    },
    {
      header: "Form ID",
      accessorKey: "formID",
    },

    columnHelper.accessor("Form", {
      id: "Internal Name",
      cell: ({ row }) => (
        <Link style={{ float: "left" }} to={generatePath(`/forms/${row.original.formID}`)}>
          {row.original.internalName}
        </Link>
      ),
    }),
    {
      header: "Count",
      accessorKey: "count",
    },
  ];

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Top Forms</title>
      </Helmet>
      <h3>Top forms</h3>
      <p>Fleet forms with the highest number of signatures in the last 14 days</p>
      <div className="sticky-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
