import React from "react";
import { useCSVDownloader } from "react-papaparse";

export default function CSVDownloader(props) {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader type={Type.Link} filename={"registerStatusTable"} bom={true} data={props.data} className="btn">
      Download CSV
    </CSVDownloader>
  );
}
