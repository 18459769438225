import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { Link, useParams, generatePath, useNavigate } from "react-router-dom";
import api from "../logic/apiHandler";

export default function CommitteeSearchMenu() {
  const [committees, setCommittees] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getCommittees() {
      try {
        setCommittees(await api.metaData.committees());
      } catch (e) {
        console.log("e", e);
      }
    }
    getCommittees();
  }, []);

  function getMenuOptions() {
    return committees.map((committee) => {
      return {
        as: Link,
        key: committee.committeeID,
        text: committee.committeeName,
        value: committee.committeeID,
        to: generatePath("/committees/:committeeID/*", { ...params, committeeID: committee.committeeID }),
      };
    });
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      navigate(generatePath("/committees/:committeeID/*", { ...params, committeeID: highlightedRow }));
    }
  }

  return (
    <div>
      {committees.length && (
        <Dropdown
          placeholder="Switch to:"
          search
          selection
          options={getMenuOptions()}
          onChange={(e, d) => {
            setHighlightedRow(d.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          closeOnChange={true}
        />
      )}
    </div>
  );
}
