import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

export default function CanvassChartsGraph(props) {
  const colourMapping = {
    cLD: "#FAA61A",
    cLab: "#E60B0B",
    cCon: "#2C0BE6",
    cSNP: "#FFFF00",
    cPla: "#3D6B48",
    cUKI: "#18B9D1",
    cGre: "#38CB07",
    cInd: "#536663",
    cBNP: "#202020",
  };

  function renderPartyLines() {
    return Object.keys(colourMapping).map((party) => {
      return (
        <Line
          yAxisId="canvassParty"
          type="monotone"
          unit="%"
          dataKey={party}
          stroke={colourMapping[party]}
          dot={false}
          key={party}
          isAnimationActive={false}
        />
      );
    });
  }

  function getMondayTicks(data) {
    let minDate = data.reduce((a, b) => (a.date < b.date ? a : b)).date;
    let maxDate = data.reduce((a, b) => (a.date > b.date ? a : b)).date;
    let mondayTicks = [];

    let startDate = new Date(minDate);
    startDate.setDate(startDate.getDate() + ((1 + 7 - startDate.getDay()) % 7 || 7));

    while (startDate <= new Date(maxDate)) {
      mondayTicks.push(startDate.toISOString().split("T")[0]);
      startDate.setDate(startDate.getDate() + 7);
    }
    return mondayTicks;
  }

  function getDateTicks(value) {
    return value.slice(5).split("-").reverse().join("/");
  }

  const customToolTip = () => {};

  return (
    <div className="canvass-graph">
      <h3 className="title">{props.title}</h3>
      <LineChart width={400} height={200} data={props.data} syncId="canvassCharts">
        <CartesianGrid strokeDasharray="3 7" />
        <XAxis dataKey="date" tick={true} tickFormatter={getDateTicks} ticks={getMondayTicks(props.data)} />
        <YAxis
          yAxisId="canvassParty"
          label={{ value: "percent", angle: -90, position: "insideLeft" }}
          domain={[0, 100]}
        />
        <YAxis
          yAxisId="canvassVolume"
          orientation="right"
          label={{
            value: "Canvass Volume",
            angle: 90,
            position: "insideBottomRight",
          }}
          domain={[0, 500]}
          tickCount={6}
          allowDataOverflow={true}
        />
        {<Tooltip content={customToolTip} />}
        {renderPartyLines()}
        <Line
          yAxisId="canvassVolume"
          dataKey="count"
          dot={false}
          stroke="grey"
          strokeDasharray="5 5"
          isAnimationActive={false}
        />
      </LineChart>
    </div>
  );
}
