import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Icon } from "semantic-ui-react";

export default function QuestionsListTable() {
  const [questionsData, setQuestionsData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { areaID } = useParams();

  useEffect(() => {
    async function getQuestionsData() {
      setDataLoaded(false);

      try {
        setQuestionsData(await api.seatData.surveyQuestions(areaID));
        setDataLoaded(true);
      } catch (e) {
        console.log("e", e);
      }
    }

    getQuestionsData();
  }, [areaID]);

  const columns = useMemo(() => [
    {
      header: "Cycle",
      accessorKey: "Cycle",
    },
    {
      header: "Question Type",
      accessorKey: "SurveyQuestionType",
    },
    {
      header: "Question Name",
      accessorKey: "SurveyQuestionName",
    },
    {
      header: "Summary",
      accessorKey: "summary",
      cell: (props) => idfk(props.getValue()),
      enableSorting: false,
    },
    {
      header: "Total Count",
      accessorKey: "totalCount",
    },
    {
      header: "Total Last Year",
      accessorKey: "totalLastYear",
    },
  ]);

  function idfk(str) {
    str = str.split(/,\s*/);
    return str.map((line) => <div>{line}</div>);
  }

  const table = useReactTable({
    columns,
    data: questionsData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Survey Questions</title>
      </Helmet>
      {dataLoaded && (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
