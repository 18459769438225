import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { metaData } from "../logic/apiHandler";
import { Icon, Popup, Button } from "semantic-ui-react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet-async";

export default function TopCanvassersTable() {
  const [topCanvassers, setTopCanvassers] = useState([]);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false);
  let timeout;
  const timeoutLength = 1500;

  const handleOpen = () => {
    setCopyPopupOpen(true);
    timeout = setTimeout(() => {
      setCopyPopupOpen(false);
    }, timeoutLength);
  };

  const handleClose = () => {
    setCopyPopupOpen(false);
    clearTimeout(timeout);
  };

  useEffect(() => {
    (async () => {
      setTopCanvassers(await metaData.topCanvassers());
    })();
  }, []);

  let allRows = prepareRowsToCopy(topCanvassers);

  function prepareRowsToCopy(data) {
    let res = [];
    if (data.length > 0) {
      res.push(Object.keys(data[0]).join("\t"));
    }
    for (let row of data) {
      res.push(Object.values(row).join("\t"));
    }
    return res.join("\n");
  }

  const columns = useMemo(
    () => [
      {
        header: "VAN ID",
        accessorKey: "VANID",
      },
      {
        header: "Attempts last 7 days",
        accessorKey: "doorPhoneContactsLast7Days",
      },
      {
        header: "Attempts last 3 months",
        accessorKey: "doorPhoneContactsLast3Mths",
      },
      {
        header: "Attempts last 18 months",
        accessorKey: "doorPhoneContactsLast18Mths",
      },
      {
        header: "First Name",
        accessorKey: "FirstName",
      },
      {
        header: "Last Name",
        accessorKey: "LastName",
      },
      {
        header: "Postal Code",
        accessorKey: "PostalCode",
      },
      {
        header: "SF ID",
        accessorKey: "SFID",
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Member status",
        accessorKey: "memberStatus",
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: topCanvassers,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Top Canvassers</title>
      </Helmet>
      <h3>
        Top Canvassers &nbsp;
        <Popup
          trigger={
            <Button basic icon>
              <CopyToClipboard text={allRows}>
                <Icon name="copy outline" size="large" />
              </CopyToClipboard>
            </Button>
          }
          content={`Top canvassers copied to clipboard`}
          on="click"
          open={isCopyPopupOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          position="top right"
        />
      </h3>
      <p>Canvassers with the most canvass attempts in the last 7 days</p>
      <div className="sticky-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
