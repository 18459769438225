import React from "react";
import { useParams } from "react-router-dom";
import ScorePerformanceChesterCharts from "./ScorePerformanceChesterCharts";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";

export default function ScorePerformanceScreen({ areaName }) {
  let { areaID } = useParams();

  return (
    <div>
      <Helmet>
        <title>{areaName} - Score Performance</title>
      </Helmet>

      <Grid stackable columns={16}>
        <GridRow>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="Con2019" colour="blue" title="Conservative 2019 comb" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="Lab2019" colour="red" title="Labour 2019 comb" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="LD2019" colour="#FAA61A" title="Lib Dem 2019 comb" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="Rem2019" colour="purple" title="Remain 2019 comb" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="Bre2019" colour="#18B9D1" title="Brexit party 2019 comb" />
          </GridColumn>
          {areaID.startsWith("S") ? (
            <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
              <ScorePerformanceChesterCharts scoreName="SNP2019" colour="yellow" title="SNP 2019 comb" />
            </GridColumn>
          ) : null}
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts scoreName="Age2024" colour="purple" title="Age 2024" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScorePerformanceChesterCharts
              scoreName="ConLab2019"
              colour="blue"
              title="ConLab 2019 comb"
              isComparison={true}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
}
